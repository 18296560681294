<template>
  <div class="gauge">
    <img src="@/assets/img/circle.png" class="gauge-bg" alt="">
    <div class="gauge-circle">
      <img src="@/assets/img/circle2.png" class="gauge1" alt="">
      <img :style="{transform: `rotate(${-180 + 180 * _percent / 100}deg)`}" src="@/assets/img/circle3.png" class="gauge2" alt="">
    </div>
    <div ref="gauge" class="gauge-container"></div>
    <div class="info">
      <div class="percent">{{percent}}%</div>
      <div class="total">{{score}}</div>
      <div class="class">{{name}}</div>
    </div>
  </div>
</template>

<script>
  function pxToVw(px) {
    let res = 0
    let base = 1920
    let cur = window.innerWidth
    res = (px / base) * cur
    return res
  }
  let gaugeChart = null;
  export default {
    name: "Gauge",
    components: {},
    data() {
      return {
        _percent: 0
      }
    },
    watch: {},
    computed: {},
    props: {
      percent: {
        type: [String, Number],
        default: 0
      },
      score: {
        type: [String, Number],
        default: 0
      },
      name: {
        type: String,
        default: '-'
      }
    },
    methods: {
      init() {
        const dom = this.$refs['gauge'];
        gaugeChart = this.$echarts.init(dom);
        let option = {
          grid: {
            top: '0%',
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: false
          },
          series: [
            {
              type: 'gauge',
              center: ['50%', '50%'],
              radius: '100%',
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: 100,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [{
                    offset: 0, color: '#b12251' // 0% 处的颜色
                  }, {
                    offset: 1, color: '#7f1982' // 100% 处的颜色
                  }],
                  global: false
                }
              },
              progress: {
                show: true,
                roundCap: true,
                width: pxToVw(15)
              },
              pointer: {
                show:false,
              },
              axisLine: {
                show:false,
              },
              axisTick: {
                show:false,
              },
              splitLine: {
                show:false,
              },
              axisLabel: {
                show:false,
              },
              title: {
                show: false
              },
              detail: {
                show:false
              },
              data: [
                {
                  value: this.percent
                }
              ]
            },
          ]
        };
        gaugeChart.setOption(option);
      }
    },
    mounted() {
      this.init();
      window.addEventListener('resize', function () {
        if (gaugeChart) {
          gaugeChart.resize();
        }
      })
      setTimeout(() => {
        this._percent = this.percent
      }, 10)
    },
  }
</script>

<style scoped lang="scss">
  .gauge{
    width: 200px;
    position: relative;
    .gauge-container{
      width: 200px;
      height: 200px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .gauge-bg{
      width: 200px;
      height: 108px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
    }
    .gauge-circle{
      width: 160px;
      height: 80px;
      position: absolute;
      left: 20px;
      top: 20px;
      overflow: hidden;
      z-index: 10;
      .gauge1{
        width: 160px;
        height: 80px;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
      }
      .gauge2{
        width: 160px;
        height: 80px;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 20;
        transform: rotate(-180deg);
        transform-origin: center 80px;
        transition: 0.8s ease-in-out;
      }
    }
    .info{
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding-top: 60px;
      .percent{
        width: 100%;
        font-size: 22px;
        font-family: DIN;
        font-weight: bold;
        text-align: center;
      }
      .total{
        width: 100%;
        font-size: 22px;
        font-family: DIN;
        text-align: center;
      }
      .class{
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #757a78;
      }
    }

  }
</style>
