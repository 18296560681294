// 正式 let schoolId = "1696765371198959617";
// 正式 let gradeId = "1696765371677110276";
// 测试 let schoolId = "1645969312962629633";
// 测试 let gradeId = "10";

// let schoolId = "1645969312962629633";
// let gradeId = "10";
let schoolId = "1696765371198959617";
let gradeId = "1696765371677110276";
export {
  schoolId, gradeId
}
