<template>
  <router-view/>
</template>
<style lang="scss">
  @import "assets/scss/normalize.css";
  @font-face {
    font-family: DIN;
    src: url('./assets/font/DIN.otf');
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>
