<template>
  <div class="page-container" :class="pageLoaded ? 'page-container-on': ''">
    <img src="@/assets/img/top.png" class="header-img" alt="">
    <div class="left-part">
      <div class="total-board">
        <div class="title">全校星累计数</div>
        <div class="numbers">
          <div class="item" v-for="(item, idx) in 6">
          </div>
          <NumberRoll ref="numberRoll"  class="number-roll" :start="0" :end="total" :totalLength="6" ></NumberRoll>
        </div>
      </div>
      <div class="split"></div>
      <swiper
              class="bar-swiper-out"
              :speed="400"
              :modules="modules"
              :autoplay="{
          delay: 20*1000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide class="slide">
          <div class="bar-title">
            课堂发星次数统计
          </div>
          <div class="star-total mb12">{{scoreTotal}}</div>
          <Bar v-for="item in scoreList" :name="item.lessonName" :key="item.lessonId" :value="item.score" :total="scoreTotal"></Bar>
          <div class="bar-title mt60 mb12">
            系统使用次数统计
          </div>
          <swiper
                  class="bar-swiper"
                  :direction="'vertical'"
                  :speed="400"
                  :slides-per-view="4"
                  :modules="modules"
                  :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
          >
            <swiper-slide v-for="(item, idx) in operateList" :key="idx + '_' + item.operateId" class="slide">
              <Bar :name="item.operateName" :value="item.count" :total="operateTotal"></Bar>
            </swiper-slide>
          </swiper>
        </swiper-slide>
        <swiper-slide class="slide-out">
          <div class="bar-title bar-title-small mb12">
            发星详情
          </div>
          <swiper
                  class="medal-swiper"
                  :direction="'vertical'"
                  :speed="400"
                  :slides-per-view="11"
                  :modules="modules"
                  :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
          >
            <swiper-slide v-for="(item, idx) in medalList" :key="idx + '_' + item.schoolMedalId" class="slide">
              <Bar :name="item.medalName" :value="item.number" :total="medalTotal"></Bar>
            </swiper-slide>
          </swiper>
        </swiper-slide>
      </swiper>
    </div>
    <div class="right-part">
      <img src="@/assets/img/week.png" class="week-img" alt="">
      <div class="week-list">
        <div class="item" @click="setGrade(item)" :class="gradeId == item.id ? 'item-on': ''" v-for="item in gradeList" :key="item.id">{{item.name.substring(0,1)}}</div>
      </div>
      <div class="right-container">
        <div class="right-top">
          <div class="right-left" :class="dayStarLoaded ? 'right-left-on': ''">
            <div class="title">
              <img src="@/assets/img/star.png" alt="">
              <span>今日得星汇总</span>
            </div>
            <div class="empty" v-if="dayStarList.length == 0">
              <img src="@/assets/img/logo.png" alt="">
              <div class="empty-title">空白的数据，是你即将填满的故事开始~</div>
            </div>
            <div class="item" :style="{transitionDelay: 0.03 * idx  + 's'}" v-for="(item, idx) in dayStarList" :key="'day' + item.studentId">
              <div class="rank">
                <img class="star" v-if="idx + 1 == 1" src="@/assets/img/star1.png" alt="">
                <img class="star" v-if="idx + 1 == 2" src="@/assets/img/star2.png" alt="">
                <img class="star" v-if="idx + 1 == 3" src="@/assets/img/star3.png" alt="">
                <span v-if="idx + 1 > 3">{{idx + 1}}</span>
              </div>
              <div class="class">{{item.className}}</div>
              <div class="name">{{item.studentName}}</div>
              <div class="total">{{item.score}}</div>
            </div>
          </div>
          <div class="stack">
            <div class="stack-title">今日课堂活跃度(%)</div>
            <div class="stack-wrapper">
              <StackBar :list="vitalitylist"></StackBar>
            </div>
          </div>
          <div class="right-left right-left2" :class="monthStarLoaded ? 'right-left-on': ''">
            <div class="title">
              <img src="@/assets/img/month.png" alt="">
              <span>本月得星汇总</span>
            </div>
            <div class="empty" v-if="monthStarList.length == 0">
              <img src="@/assets/img/logo.png" alt="">
              <div class="empty-title">空白的数据，是你即将填满的故事开始~</div>
            </div>
            <div class="item" :style="{transitionDelay: 0.03 * idx  + 's'}" v-for="(item, idx) in monthStarList" :key="'month' + item.studentId">
              <div class="rank">
                <img class="star" v-if="idx + 1 == 1" src="@/assets/img/month1.png" alt="">
                <img class="star" v-if="idx + 1 == 2" src="@/assets/img/month2.png" alt="">
                <img class="star" v-if="idx + 1 == 3" src="@/assets/img/month3.png" alt="">
                <span v-if="idx + 1 > 3">{{idx + 1}}</span>
              </div>
              <div class="class">{{item.className}}</div>
              <div class="name">{{item.studentName}}</div>
              <div class="total">{{item.score}}</div>
            </div>
          </div>
        </div>
        <div class="right-bottom">
          <div class="right-left">
            <div class="title">
              <span>月度累计及目标达成度</span>
            </div>
            <div class="gauge-list" :class="classLoaded ? 'gauge-list-on': ''" >
              <div class="empty" v-if="classList.length == 0">
                <img src="@/assets/img/logo.png" alt="">
                <div class="empty-title">空白的数据，是你即将填满的故事开始~</div>
              </div>
              <Gauge class="gauge-item" :style="{transitionDelay: 0.03 * idx  + 's'}" v-for="(item, idx) in classList" :key="item.classId" :percent="curGrade.target ? Math.round(+item.score*100/+curGrade.target): 0" :name="item.className" :score="item.score"></Gauge>
            </div>
          </div>
          <div class="right-right" :class="teacherLoaded ? 'right-right-on': ''">
            <div class="title">
              <span>本月发星汇总</span>
            </div>
            <div class="empty" v-if="teacherList.length == 0">
              <img src="@/assets/img/logo.png" alt="">
              <div class="empty-title">空白的数据，是你即将填满的故事开始~</div>
            </div>
            <swiper
                    class="teacher-swiper"
                    :direction="'vertical'"
                    :speed="400"
                    :slides-per-view="8"
                    :modules="modules"
                    :autoplay="{
              delay: 2500,
              disableOnInteraction: false,
            }"
            >
              <swiper-slide v-for="(item, idx) in teacherList" :key="'key' + gradeId + '_' + idx" class="slide">
                <div class="item" v-for="item2 in item" :key="item2.teacherId" :style="{transitionDelay: 0.03 * idx  + 's'}" >
                  <div class="name">{{item2.teacherName}}</div>
                  <div class="total">{{item2.score}}</div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  function fixZeroStart(str, n) {
    return (Array(n).join(0) + str).slice(-n);
  }

  import Bar from '@/components/Bar.vue';
  import StackBar from '@/components/StackBar.vue';
  import Gauge from '@/components/Gauge.vue';
  import * as api from '@/api'
  import {schoolId} from "@/config";
  import NumberRoll from 'vue-number-roll'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Autoplay } from 'swiper/modules';
  import 'swiper/css'
  export default {
    name: "Home",
    components: {
      Bar, StackBar, Gauge, NumberRoll,  Swiper,
      SwiperSlide
    },
    data() {
      return {
        modules: [Autoplay],
        total: 0,
        pageLoaded: false,
        scoreTotal: 0,
        scoreList: [],
        operateTotal: 0,
        operateList: [],
        medalList: [],
        medalTotal: 0,
        classList: [],
        gradeList: [],
        dayStarList: [],
        monthStarList: [],
        teacherList: [],
        curGrade: {},
        vitalitylist: [],
        gradeId: 0,
        dayStarLoaded: true,
        monthStarLoaded: true,
        classLoaded: true,
        teacherLoaded: true,
        timer: null,
        leftTimer: null
      }
    },
    watch: {
      gradeId(nv, ov) {
        this.getScoreList(ov == 0);
        this.getStudentScoreRank(ov == 0);
        this.getStudentScoreRankMonth(ov == 0);
        this.getTeacherScoreList(ov == 0);
        this.getVitalityList();
      }
    },
    computed: {},
    methods: {
      setGrade(item) {
        this.curGrade = item;
        this.gradeId = item.id
      },
      async getSchoolTotal() {

        const {data} = await api.schoolTotal({schoolId: schoolId})
        const numberRoll = this.$refs['numberRoll'];
        this.total = data.result[0].totalScore
        setTimeout(() => {
          numberRoll.roll();
        }, 300)
      },
      async getScoreRank() {
        const {data} = await api.lessonScoreRank({schoolId: schoolId})
        this.scoreList = data.result;
        this.scoreTotal = data.result.reduce((pre, cur) => pre + cur.score, 0)
      },
      async getCountRank() {
        const {data} = await api.operateCountRank({schoolId: schoolId})
        this.operateList = data.result;
        this.operateTotal = data.result.reduce((pre, cur) => pre + cur.count, 0)
      },
      async getMedalRank() {
        const {data} = await api.actionMedalRank({schoolId: schoolId})
        this.medalList = data.result;
        this.medalTotal = data.result.reduce((pre, cur) => pre + cur.number, 0)
      },
      async getScoreList(init) {
        this.classLoaded = false
        const {data} = await api.classScoreList({schoolGradeId: this.gradeId})
        if (init || !data.result.length) {
          this.classList = data.result;
          setTimeout(() => {
            this.classLoaded = true
          }, 50)
        } else {
          setTimeout(() => {
            this.classList = data.result;
            setTimeout(() => {
              this.classLoaded = true
            }, 0)
          }, 300 + this.classList.length * 30)
        }
      },
      async getGradeList() {
        const {data} = await api.gradeList({schoolId: schoolId})
        this.gradeList = data.result;
        this.curGrade = this.gradeList[0]
        this.gradeId = this.gradeList[0].id
        if (!this.timer) {
          this.timer = setInterval(() => {
            let idx = this.gradeList.findIndex(item => item.id == this.gradeId);
            if (idx != -1) {
              if (idx == this.gradeList.length - 1) {
                idx = 0;
              } else {
                idx = idx + 1
              }
              this.curGrade = this.gradeList[idx]
              this.gradeId = this.gradeList[idx].id
            }
          }, 20*1000);
        } else {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      async getStudentScoreRank(init) {
        let month = fixZeroStart((new Date().getMonth() + 1), 2);
        let day = fixZeroStart(new Date().getDate(), 2);
        let date = new Date().getFullYear() + '-' + month + '-' + day
        this.dayStarLoaded = false
        const {data} = await api.studentScoreRank({date, schoolGradeId: this.gradeId})
        if (init || !data.result.length) {
          this.dayStarList = data.result;
          setTimeout(() => {
            this.dayStarLoaded = true
          }, 50)
        } else {
          setTimeout(() => {
            this.dayStarList = data.result;
            setTimeout(() => {
              this.dayStarLoaded = true
            }, 0)
          }, 300 + this.dayStarList.length * 30)
        }
      },
      async getStudentScoreRankMonth(init) {
        let month = fixZeroStart((new Date().getMonth() + 1), 2);
        let date = new Date().getFullYear() + '-' + month
        this.monthStarLoaded = false
        const {data} = await api.studentScoreRank({date, schoolGradeId: this.gradeId})
        if (init || !data.result.length) {
          this.monthStarList = data.result;
          setTimeout(() => {
            this.monthStarLoaded = true
          }, 50)
        } else {
          setTimeout(() => {
            this.monthStarList = data.result;
            setTimeout(() => {
              this.monthStarLoaded = true
            }, 0)
          }, 300 + this.monthStarList.length * 30)
        }
      },
      async getTeacherScoreList(init) {
        this.teacherLoaded = false
        const {data} = await api.teacherScoreList({schoolGradeId: this.gradeId})
        let arr = [];
        if (data.result) {
          data.result.forEach((item, idx) => {
            let arrIdx = Math.floor(idx / 2);
            if (!arr[arrIdx]) {
              arr[arrIdx] = []
            }
            arr[arrIdx].push(item);
          })
        }
        if (init || !data.result.length) {
          this.teacherList = arr;
          setTimeout(() => {
            this.teacherLoaded = true
          }, 50)
        } else {
          setTimeout(() => {
            this.teacherList = arr;
            setTimeout(() => {
              this.teacherLoaded = true
            }, 0)
          }, 300 + arr.length * 30)
        }
      },
      async getVitalityList() {
        const {data} = await api.vitalityList({schoolGradeId: this.gradeId})
        this.vitalitylist = data.result
      },
    },
    mounted() {
      setTimeout(()=> {
        this.pageLoaded = true
      }, 0)
      this.getGradeList();

      this.getSchoolTotal();
      this.getScoreRank();
      this.getCountRank();
      this.getMedalRank();
      this.leftTimer = setInterval(() => {
        this.getSchoolTotal();
        this.getScoreRank();
        this.getCountRank();
        this.getMedalRank();
      }, 20*1000)
    },
    beforeUnmount() {
      clearInterval(this.timer)
      this.timer = null
      clearInterval(this.leftTimer)
      this.leftTimer = null
    },
  }
</script>

<style scoped lang="scss">
  .page-container{
    width: 1920px;
    height: 1080px;
    @include linearGradient(180deg, #ffe8ef, #fff5f8);
    box-sizing: border-box;
    padding-top: 134px;
    padding-left: 32px;
    padding-right: 32px;
    @include clear;
    .header-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 1920px;
      height: 134px;
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      transform: translateY(-40px);
      transition: 0.8s ease;
    }
    .left-part{
      width: 272px;
      height: 916px;
      background: #fff;
      border-radius: 20px;
      float: left;
      position: relative;
      z-index: 20;
      @include linearGradient(0deg, #f5fffb, #fefeff);
      box-sizing: border-box;
      border: 3px solid #fff;
      opacity: 0;
      transform: translateY(40px);
      transition: 0.8s opacity ease, 0.8s transform ease;
      transition-delay: 0.1s;
      padding-top: 24px;
      .total-board{
        width: 224px;
        height: 154px;
        background: url("../assets/img/total.png");
        background-size: contain;
        margin: 0 auto;
        .title{
          width: 100%;
          text-align: center;
          font-size: 26px;
          font-weight: bold;
          color: #fff;
          padding-top: 26px;
        }
        .numbers{
          width: 180px;
          @include clear;
          margin: 0 auto;
          padding-top: 14px;
          position: relative;
          .item{
            float: left;
            width: 30px;
            height: 52px;
            text-align: center;
            line-height: 52px;
            background: url("../assets/img/number.png");
            background-size: cover;
            font-size: 36px;
            font-weight: bold;
            font-family: DIN;
            color: #fff;
          }
          .number-roll{
            width: 180px;
            position: absolute;
            top: 14px;
            left: 0;
            :deep(.lh-none) {
              width: 30px;
              text-align: center;
              height: 52px;
              line-height: 52px;
              font-size: 36px;
              font-weight: bold;
              font-family: DIN;
              color: #fff;
            }
          }
        }
      }
      .split{
        width: 100%;
        height: 2px;
        background: #f3f4f4;
        margin-top: 24px;
      }
      .bar-title{
        width: 100%;
        font-size: 26px;
        color: #000;
        font-weight: bold;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 0 22px;
      }
      .bar-title-small{
        font-size: 22px;
      }
      .star-total{
        font-size: 30px;
        font-weight: bold;
        font-family: DIN;
        box-sizing: border-box;
        padding: 0 22px;
      }
      .mt60{
        margin-top: 40px;
      }
      .mb12{
        margin-bottom: 12px;
      }
      .bar-swiper-out{
        width: 100%;
        height: 700px;
      }
      .bar-swiper{
        width: 272px;
        height: 260px;
        overflow: hidden;
      }
      .medal-swiper{
        width: 100%;
        height: 700px;

      }
    }
    .right-part{
      opacity: 0;
      transform: translateY(40px);
      transition: 0.8s opacity ease, 0.8s transform ease;
      transition-delay: 0.2s;
      float: right;
      width: 1552px;
      height: 984px;
      background: #fff;
      border-radius: 20px;
      position: relative;
      top: -68px;
      z-index: 20;
      @include linearGradient(0deg, #f5fffb, #fefeff);
      box-sizing: border-box;
      border: 3px solid #fff;
      .week-img{
        @include horizontal();
        top: -22px;
        width: 1510px;
        height: 125px;
      }
      .week-list{
        @include horizontal();
        width: 435px;
        height: 64px;
        line-height: 64px;
        display: flex;
        justify-content: space-between;
        top: 0px;
        z-index: 50;
        .item{
          font-size: 26px;
          color: #7a757a;
          font-weight: 300;
          transition: 0.3s ease;
        }
        .item-on{
          font-size: 36px;
          color: #b22250;
          font-weight: bold;
        }
      }
      .right-container{
        width: 100%;
        .empty{
          padding-top: 50px;
          width: 270px;
          transition: 0.3s ease;
          opacity: 0;
          transform: translateX(-20px);
          img{
            @include fit(contain);
          }
          .empty-title{
            width: 100%;
            font-size: 14px;
            color: #666;
            text-align: center;
          }
        }
        .right-top{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .stack{
            width: 960px;
            float: left;
            padding-top: 100px;
            .stack-title{
              width: 100%;
              text-align: center;
              font-size: 26px;
              font-weight: bold;
              height: 50px;
              line-height: 50px;
            }
            .stack-wrapper{
              width: 964px;
              height: 430px;
            }
          }
          .right-left{
            width: 280px;
            float: left;
            height: 580px;
            box-sizing: border-box;
            padding-top: 38px;
            margin-left: 24px;
            .title{
              width: 100%;
              height: 30px;
              line-height: 30px;
              margin-bottom: 28px;
              img{
                width: 30px;
                height: 29px;
                vertical-align: top;
                margin-right: 9px;
              }
              span{
                font-weight: bold;
                color: #000;
                font-size: 26px;
              }
            }
            .item{
              width: 100%;
              @include clear;
              margin-bottom: 19px;
              transition: 0.3s ease;
              opacity: 0;
              transform: translateX(-20px);
              &:last-child{
                margin-bottom: 0px;
              }
              .rank{
                float: left;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 18px;
                color: #b22250;
                font-family: DIN;
                .star{
                  width: 30px;
                  height: 30px;
                }
              }
              .class{
                font-size: 16px;
                color: #000;
                float: left;
                line-height: 30px;
                height: 30px;
                text-align: center;
                width: 90px;
                margin-left: 5px;
                white-space: nowrap;
              }
              .name{
                font-size: 16px;
                color: #000;
                float: left;
                line-height: 30px;
                height: 30px;
                text-align: center;
                width: 80px;
                box-sizing: border-box;
                padding: 0 5px;
                @include elli;
              }
              .total{
                font-size: 18px;
                font-family: DIN;
                color: #000;
                float: left;
                line-height: 30px;
                height: 30px;
                width: 60px;
                text-align: center;
              }
            }
          }
          .right-left2{
            .item{
              transform: translateX(20px);
            }
            .empty{
              transform: translateX(20px);
            }
          }
          .right-left-on{
            .item{
              opacity: 1;
              transform: translateX(0px);
            }
            .empty{
              opacity: 1;
              transform: translateX(0px);
            }
          }
        }
        .right-bottom{
          width: 100%;
          .right-left{
            width: calc(100% - 280px);
            float: left;
            height: 380px;
            box-sizing: border-box;
            padding-top: 25px;
            .title{
              width: 100%;
              height: 30px;
              line-height: 30px;
              margin-bottom: 20px;
              box-sizing: border-box;
              padding-left: 24px;
              span{
                font-weight: bold;
                color: #000;
                font-size: 26px;
              }
            }
            .gauge-list{
              box-sizing: border-box;
              padding-left: 24px;
              width: 100%;
              height: 200px;
              @include clear;
              padding-top: 10px;
              .empty{
                padding-top: 0px;
                transform: translateX(-20px);
              }
              .gauge-item{
                width: 200px;
                height: 140px;
                margin-bottom: 20px;
                float: left;
                transition: 0.3s ease;
                opacity: 0;
                transform: translateY(20px);
                margin-right: 55px;
                &:nth-child(5n) {
                  margin-right: 0px;
                }
              }
            }
            .gauge-list-on{
              .gauge-item{
                opacity: 1;
                transform: translateY(0px);
              }
              .empty{
                opacity: 1;
                transform: translateY(0px);
              }
            }
          }
          .right-right{
            width: 280px;
            float: left;
            box-sizing: border-box;
            padding-top: 25px;
            padding-left: 24px;
            .empty{
              padding-top: 0px;
              transform: translateX(20px);
            }
            .title{
              width: 100%;
              height: 30px;
              line-height: 30px;
              margin-bottom: 20px;
              span{
                font-weight: bold;
                color: #000;
                font-size: 26px;
              }
            }
            .teacher-swiper{
              width: 100%;
              height: 300px;
            }
            .item{
              width: 120px;
              @include clear;
              float: left;
              margin-bottom: 20px;
              margin-right: 10px;
              transition: 0.3s ease;
              opacity: 0;
              transform: translateX(20px);
              &:last-child{
                margin-bottom: 0px;
              }
              &:nth-child(odd) {
                margin-right: 0px;
              }
              .name{
                font-size: 16px;
                color: #000;
                float: left;
                line-height: 20px;
                height: 20px;
                text-align: center;
                width: 60px;
                box-sizing: border-box;
                @include elli;
              }
              .total{
                font-size: 18px;
                color: #000;
                float: left;
                line-height: 20px;
                height: 20px;
                text-align: center;
                width: 60px;
                box-sizing: border-box;
                font-family: DIN;
              }

            }

          }
          .right-right-on{
            .item{
              opacity: 1;
              transform: translateX(0px);
            }
            .empty{
              opacity: 1;
              transform: translateX(0px);
            }
          }
        }
      }
    }
    .myChart{
      width: 270px;
      height: 270px;
    }
  }
  .page-container-on{
    .header-img, .left-part, .right-part{
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
