<template>
  <div class="bar-info">
    <div class="name">{{name}}</div>
    <div class="value">{{value}}</div>
  </div>
  <div class="bar-container">
    <div class="bar-progress" :style="{width: width + '%'}"></div>
  </div>
</template>

<script>
  export default {
    name: "Bar",
    components: {},
    props: {
      name: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number],
        default: 0
      },
      total: {
        type: [String, Number],
        default: 0
      }
    },
    watch: {
      value() {
        this.width = (+this.value / +this.total) * 100;
      }
    },
    data() {
      return {
        width: 0,
      }
    },
    computed: {},
    methods: {},
    mounted() {
      setTimeout(() => {
        this.width = (+this.value / +this.total) * 100;
      }, 0)
    },
  }
</script>

<style scoped lang="scss">
  .bar-info{
    width: 100%;
    height: 32px;
    line-height: 32px;
    @include clear;
    box-sizing: border-box;
    padding: 0 22px;
    .name{
      float: left;
      font-size: 16px;
      color: #000;
    }
    .value{
      float: right;
      font-size: 18px;
      color: #000;
      font-family: DIN;
    }
  }
  .bar-container{
    width: calc(100% - 44px);
    height: 16px;
    border-radius: 16px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11) inset;
    background: rgba(0, 0, 0, 0.04);
    margin-bottom: 15px;
    margin-left: 22px;
    .bar-progress{
      height: 16px;
      border-radius: 16px;
      @include linearGradient(90deg, #b22250, #e64276);
      box-shadow: 0 0 6px 0 rgba(#740b2d, 0.38);
      transition: 1s ease;
    }
  }
</style>
