<template>
  <div class="stack-container">
    <div class="empty" :class="!show ? 'empty-on': ''">
      <img src="@/assets/img/logo.png" alt="">
      <div class="empty-title">空白的数据，是你即将填满的故事开始~</div>
    </div>
    <div ref="stack" id="stack"></div>
  </div>
</template>

<script>
  import {sortBy} from 'lodash';
  function pxToVw(px) {
    let res = 0
    let base = 1920
    let cur = window.innerWidth
    res = (px / base) * cur
    return res
  }
  let stackChart = null;
  export default {
    name: "StackBar",
    components: {},
    data() {
      return {
        show: true
      }
    },
    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      }
    },
    watch: {
      list() {
        this.init()
      }
    },
    computed: {},
    methods: {
      init() {
        this.show = true;
        let len = this.list.length;
        let list = JSON.parse(JSON.stringify(this.list))
        // let list = [
        //   {
        //     "classId": "1",
        //     "className": "一（1）班",
        //     "classVitalityValue": 100,
        //     "teacherVitalityList": [
        //       {
        //         "teacherId": "1",
        //         "teacherName": "教师1",
        //         "teacherVitalityValue": 12
        //       },
        //       {
        //         "teacherId": "1732280022179225601",
        //         "teacherName": "王尚将",
        //         "teacherVitalityValue": 30
        //       },
        //       {
        //         "teacherId": "1716710550736732162",
        //         "teacherName": "杨梅",
        //         "teacherVitalityValue": 40
        //       }
        //     ]
        //   },
        //   {
        //     "classId": "1",
        //     "className": "一（2）班",
        //     "classVitalityValue": 100,
        //     "teacherVitalityList": [
        //       {
        //         "teacherId": "1",
        //         "teacherName": "教师2",
        //         "teacherVitalityValue": 58
        //       },
        //       {
        //         "teacherId": "1732280022179225601",
        //         "teacherName": "王尚22",
        //         "teacherVitalityValue": 5
        //       },
        //       {
        //         "teacherId": "1716710550736732162",
        //         "teacherName": "杨梅222",
        //         "teacherVitalityValue": 37
        //       }
        //     ]
        //   },
        //   {
        //     "classId": "1",
        //     "className": "一（3）班",
        //     "classVitalityValue": 100,
        //     "teacherVitalityList": [
        //       {
        //         "teacherId": "1",
        //         "teacherName": "333",
        //         "teacherVitalityValue": 10
        //       },
        //       {
        //         "teacherId": "1732280022179225601",
        //         "teacherName": "331",
        //         "teacherVitalityValue": 15
        //       },
        //       {
        //         "teacherId": "1716710550736732162",
        //         "teacherName": "332",
        //         "teacherVitalityValue": 80
        //       }
        //     ]
        //   },
        // ]
        list = list.map(item => {
          item.teacherVitalityList = sortBy(item.teacherVitalityList, ['teacherVitalityValue']).reverse();
          return item
        })
        if (!len) {
          if (stackChart) {
            stackChart.dispose();
          }
          this.show = false;
          return;
        }
        if (stackChart) {
          stackChart.dispose();
        }

        let arr1 = []
        let arr2 = []
        let color = ['rgba(178, 34, 80, 1)', 'rgba(178, 34, 80, 0.8)', 'rgba(178, 34, 80, 0.6)', 'rgba(178, 34, 80, 0.4)' ,'rgba(178, 34, 80, 0.2)'];
        let color2 = ['rgba(127, 25, 131, 1)', 'rgba(127, 25, 131, 0.8)', 'rgba(127, 25, 131, 0.6)', 'rgba(127, 25, 131, 0.4)' ,'rgba(127, 25, 131, 0.2)'];
        let xData = list.map(item => item.className)
        list.forEach((item, i) => {
          if (i % 2 == 0 && item.teacherVitalityList) {
            item.teacherVitalityList.forEach((item2, i2) => {
              let data2 = []
              let min = i2 == item.teacherVitalityList.length - 1;
              data2[i] = {
                value: item2.teacherVitalityValue,
                itemStyle: {
                  borderRadius: [0, 0, 0, 0]
                }
              }
              arr1.push({
                name: 'type1_' + i,
                type: 'bar',
                label: {
                  show: true,
                  fontSize: pxToVw(16),
                  color: '#000000',
                  textBorderColor: 'rgba(255,255,255,0.5)',
                  textBorderWidth: pxToVw(1),
                  position: 'top',
                  distance: i2 * 10, // 动态增加垂直间距
                  formatter: (params) => {
                    return item2.teacherName;
                  }
                },
                barWidth: pxToVw(64),
                stack: 'type1',
                color: color[i2],
                data: data2
              })
            })
          } else {
            item.teacherVitalityList.forEach((item2, i2) => {
              let data2 = []
              let min = i2 == item.teacherVitalityList.length - 1;
              data2[i] = {
                value: item2.teacherVitalityValue,
                itemStyle: {
                  borderRadius: [0, 0, 0, 0]
                }
              }
              arr2.push({
                name: 'type2_' + i,
                type: 'bar',
                label: {
                  show: true,
                  fontSize: pxToVw(16),
                  color: '#000000',
                  textBorderColor: 'rgba(255,255,255,0.5)',
                  textBorderWidth: pxToVw(1),
                  position: 'top',
                  distance: i2 * 10, // 动态增加垂直间距
                  formatter: (params) => {
                    return item2.teacherName;
                  }
                },
                barWidth: pxToVw(64),
                barGap: '-100%',
                stack: 'type2',
                color: color2[i2],
                data: data2
              })
            })
          }
        })
        let series = [
          ...arr1,
          ...arr2
        ]
        let total1 = [];
        let total2 = []
        arr1.forEach((item) => {
          item.data.forEach((item2, idx) => {
            if (item2 == '-') {
              total1[idx] = '-'
            } else {
              if (!total1[idx]) total1[idx] = 0
              total1[idx] = total1[idx] + item2
            }
          })
        })
        arr2.forEach((item) => {
          item.data.forEach((item2, idx) => {
            if (item2 == '-') {
              total2[idx] = '-'
            } else {
              if (!total2[idx]) total2[idx] = 0
              total2[idx] = total2[idx] + item2
            }
          })
        })
        const dom = this.$refs['stack'];
        stackChart = this.$echarts.init(dom); // 初始化echarts实例
        if (!series.length) {
          if (stackChart) {
            stackChart.dispose();
          }
          this.show = false;
          return;
        }
        series.push(
          {
            name: 'label',
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              fontSize: pxToVw(22),
              fontFamily: 'DIN',
              color: '#000000',
              fontWeight: 'bold'
            },
            barWidth: pxToVw(64),
            color: 'rgba(0, 0, 0, 0)',
            data: total1
          },
          {
            name: 'label2',
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              fontSize: pxToVw(22),
              fontFamily: 'DIN',
              color: '#000000',
              fontWeight: 'bold'
            },
            barGap: '-100%',
            barWidth: pxToVw(64),
            color: 'rgba(0, 0, 0, 0)',
            data: total2
          })
        const option = {
          tooltip: {
            show: false
          },
          legend: {
            show: false
          },
          grid: {
            top: pxToVw(50),
            left: '1%',
            right: '0%',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: xData,
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              lineHeight: pxToVw(36),
              fontSize: pxToVw(16)
            }
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            }
          },
          series: series
        };
        // 设置实例参数
        stackChart.setOption(option);
      }
    },
    mounted() {
      setTimeout(() => {
        this.init();
      }, 500)
      window.addEventListener('resize', function () {
        if (stackChart) {
          stackChart.resize();
        }
      })
    },
  }
</script>

<style scoped lang="scss">
  .stack-container{
    width: 100%;
    height: 100%;
    position: relative;
    #stack{
      width: 100%;
      height: 100%;
    }
    .empty{
     @include horizontal();
      width: 270px;
      transition: 0.3s ease;
      opacity: 0;
      transform: translateY(20px);
      img{
        @include fit(contain);
      }
      .empty-title{
        width: 100%;
        font-size: 14px;
        color: #666;
        text-align: center;
      }
    }
    .empty-on{
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
