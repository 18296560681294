import http from '@/axios/http';

export function schoolTotal(params){
    return http.post('/axzh/client/v1/school/totalScore', params)
}

export function lessonScoreRank(params){
    return http.post('/axzh/client/v1/lesson/scoreRank', params)
}

export function operateCountRank(params){
    return http.post('/axzh/client/v1/operate/countRank', params)
}

export function actionMedalRank(params){
    return http.post('/axzh/client/v1/medal/actionMedalRank', params)
}

export function classScoreList(params){
    return http.post('/axzh/client/v1/class/scoreList', params)
}

export function gradeTarget(params){
    return http.post('/axzh/client/v1/grade/target', params)
}

export function gradeList(params){
    return http.post('/axzh/client/v1/grade/list', params)
}

export function studentScoreRank(params){
    return http.post('/axzh/client/v1/student/scoreRank', params)
}

export function teacherScoreList(params){
    return http.post('/axzh/client/v1/teacher/scoreList', params)
}

export function vitalityList(params){
    return http.post('/axzh/client/v1/class/vitalityList', params)
}



